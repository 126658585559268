import React, { Component } from 'react';
import {Navbar} from "../Components/navbar/Navbar";
import CarbonFootprintService from '../Service/CarbonFootprintService';
import { PieChart } from 'react-minimal-pie-chart';
import "./Results.scss";
import { Footer } from './footer/Footer';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class CarbonFootprintComponent extends Component {

   

    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            subCategories: [],
            types: [],
            elements: [],
            countries: [],
            selectedElement: null,
            carbonFootprintObject:{
                id:0,
                categoryName: '',
                subCategoryName: '',
                typeName: '',
                elementName: '',
                countryName: '',
                description: '',
                weight: 0,
                carbonFootprint: 0,//weight * quantity * (measures.values)
                quantity: 0,
                measures: [] //{title:"", value:0}
            },
            carbonFootprintList: [],
            footprintCalculated: 0,
            chartArray: [],
            hovered: null,
            projectName: '',
            startDate: '',
            endDate: '',
            measures: []
        }
        
        this.getCategories = this.getCategories.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);

        this.getSubCategoriesByCategoryId = this.getSubCategoriesByCategoryId.bind(this);
        this.handleSubCategoryChange = this.handleSubCategoryChange.bind(this);

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.getTypesBySubCategoryId = this.getTypesBySubCategoryId.bind(this);

        this.handleElementChange = this.handleElementChange.bind(this);
        this.getElementsByTypeId = this.getElementsByTypeId.bind(this);

        this.addButtonClicked = this.addButtonClicked.bind(this);
        this.getCategoryNameById = this.getCategoryNameById.bind(this);
        this.getTypeById = this.getTypeById.bind(this);
        this.getElementById = this.getElementById.bind(this);
        this.generateRandomInteger = this.generateRandomInteger.bind(this);
        this.deleteCarbonFootprintRow = this.deleteCarbonFootprintRow.bind(this);
        this.checkCarbonFootprintData = this.checkCarbonFootprintData.bind(this);
        this.onClickChart = this.onClickChart.bind(this);
        this.deleteChartElement = this.deleteChartElement.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.goToNewProject = this.goToNewProject.bind(this);
        this.clearCarbonFootPrintObject = this.clearCarbonFootPrintObject.bind(this);
    }

    componentDidMount() {
        this.setState({
            projectName: this.props.location.state.projectName,
            startDate: this.props.location.state.startDate,
            endDate: this.props.location.state.endDate
        });
        this.getCategories();
    }

    /*********Categories Functions **************/
    getCategories(){
        CarbonFootprintService.getCategories().then((res) => {
            console.log(res.data);
            var category = {id: 0, title:"Categories"};
            var categories = [...res.data];
            categories.splice(0, 0, category);
            
            this.setState({ categories: categories });
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    handleCategoryChange = (event) => {
        var categoryId = event.target.value;
        console.log("categoryId:", categoryId);
            this.setState({ types: [], elements: [], countries: [], measures: [] });
            var  carbonFootprintObject = {...this.state.carbonFootprintObject};
            carbonFootprintObject.categoryName = this.getCategoryNameById(categoryId);
            carbonFootprintObject.subCategoryName = '';
            carbonFootprintObject.typeName = '';
            carbonFootprintObject.elementName = '';
            carbonFootprintObject.countryName = '';
            carbonFootprintObject.weight = 0;
    
            this.setState({ carbonFootprintObject: carbonFootprintObject});
            this.getSubCategoriesByCategoryId(categoryId);
        
    }

    /*********Sub Categories Functions **************/
    getSubCategoriesByCategoryId(categoryId){
        CarbonFootprintService.getSubCategoriesByCategoryId(categoryId).then((res) => {
            console.log(res.data);
            this.setState({ subCategories: res.data });
            if(res.data.length > 0){
                let subCategoryId = res.data[0].id;
                var carbonFootprintObject = {...this.state.carbonFootprintObject};
                carbonFootprintObject.subCategoryName = this.getSubCategoryNameById(subCategoryId);
                this.setState({ carbonFootprintObject: carbonFootprintObject});
                this.getTypesBySubCategoryId(subCategoryId);
            }
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    handleSubCategoryChange = (event) => {
        var subCategoryId = event.target.value;
        console.log("subCategoryId:", subCategoryId);
        this.setState({ elements: [], selectedElement: null });
        var  carbonFootprintObject = {...this.state.carbonFootprintObject};
        carbonFootprintObject.subCategoryName = this.getSubCategoryNameById(subCategoryId);
        carbonFootprintObject.typeName = '';
        carbonFootprintObject.elementName = '';
        carbonFootprintObject.countryName = '';
        carbonFootprintObject.weight = 0;
        this.setState({ carbonFootprintObject: carbonFootprintObject});
        this.getTypesBySubCategoryId(subCategoryId);
    }

    /*********Types Functions **************/
    getTypesBySubCategoryId(subCategoryId){
        CarbonFootprintService.getTypesBySubCategoryId(subCategoryId).then((res) => {
            console.log(res.data);
            this.setState({ types: res.data });
            if(res.data.length > 0){
                let typeId = res.data[0].id;
                var carbonFootprintObject = {...this.state.carbonFootprintObject};
                var type = this.getTypeById(typeId);
                carbonFootprintObject.typeName = type.name;
                this.setState({ carbonFootprintObject: carbonFootprintObject, measures: type.measures});
                this.getElementsByTypeId(typeId);
            }
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    handleTypeChange = (event) => {
        var typeId = event.target.value;
        console.log("typeId:", typeId);
        var carbonFootprintObject = {...this.state.carbonFootprintObject};
        var type = this.getTypeById(typeId);
        carbonFootprintObject.typeName = type.name;
        carbonFootprintObject.elementName = '';
        carbonFootprintObject.countryName = '';
        carbonFootprintObject.weight = 0;
        this.setState({ carbonFootprintObject: carbonFootprintObject, measures: type.measures});
        this.getElementsByTypeId(typeId);
    }

    /*********Elements Functions **************/
    getElementsByTypeId(typeId){
        CarbonFootprintService.getElementsByTypeId(typeId).then((res) => {
            console.log(res.data[0]);
            if(res.data.length > 0){
            var element = null;
            var elementName = "";
            var countryName = "";
            this.setState({ elements: res.data , selectedElement: res.data[0], countries: res.data[0].countries});
                let elementId = res.data[0].id;
                element = this.getElementById(elementId);
                if(element !== null){
                    elementName = element.title;
                    if(res.data[0].countries.length > 0){
                        countryName = element.countries[0].name;
                    }
                    
                }
            }
            
            var carbonFootprintObject = {...this.state.carbonFootprintObject};
            carbonFootprintObject.elementName = elementName;
            carbonFootprintObject.countryName = countryName;
            carbonFootprintObject.weight = element.weight;
            this.setState({ carbonFootprintObject: carbonFootprintObject});
            
        }).catch((error) => {
            if(error.request.status.toString() === "401" && error.response.data.error.toString() === "Unauthorized"){
                this.props.history.push('/');
            }
        });
    }

    handleElementChange = (event) => {
        var elementId = event.target.value;
        console.log("elementId:", elementId);
        var element = this.getElementById(elementId);
        if(element !== null){
            console.log("elementId:!== null");
            var carbonFootprintObject = {...this.state.carbonFootprintObject};
            carbonFootprintObject.elementName = element.title;
            if(element.countries.length > 0){
                carbonFootprintObject.countryName = element.countries[0].name;
            }
            
            carbonFootprintObject.weight = element.weight;
            this.setState({ carbonFootprintObject: carbonFootprintObject, selectedElement: element, countries: element.countries});
        }
       
    }

    

    getCategoryNameById(categoryId){
        for (let i = 0; i < this.state.categories.length; i++) {
            if(this.state.categories[i].id.toString().trim() === categoryId.toString().trim()){
                return this.state.categories[i].title;
            }
        }
        return '';
    }

    getSubCategoryNameById(subCategoryId){
        for (let i = 0; i < this.state.subCategories.length; i++) {
            if(this.state.subCategories[i].id.toString().trim() === subCategoryId.toString().trim()){
                return this.state.subCategories[i].name;
            }
        }
        return '';
    }

    getTypeById(typeId){
        for (let i = 0; i < this.state.types.length; i++) {
            if(this.state.types[i].id.toString().trim() === typeId.toString().trim()){
                return this.state.types[i];
            }
        }
        return '';
    }

    getElementById(elementId){
        for (let i = 0; i < this.state.elements.length; i++) {
            if(this.state.elements[i].id.toString().trim() === elementId.toString().trim()){
                return this.state.elements[i];
            }
        }
        return null;
    }

     generateRandomInteger() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    addButtonClicked = (event) => {
        console.log("button clicked");
        
        var array = [...this.state.carbonFootprintList];
        var carbonFootprintObject = {...this.state.carbonFootprintObject};
        if(this.checkCarbonFootprintData()){
            carbonFootprintObject.id = this.generateRandomInteger();
            var carbonFootprint = Number(carbonFootprintObject.weight.toFixed(6)) * this.getMeasuresValue();// * carbonFootprintObject.quantity;
            carbonFootprintObject.carbonFootprint = Number(carbonFootprint.toFixed(6));
            array.push(carbonFootprintObject);
            var footprintCalculated = this.state.footprintCalculated + Number(carbonFootprintObject.carbonFootprint.toFixed(6));
            
            var title = carbonFootprintObject.categoryName + "-" + carbonFootprintObject.subCategoryName;
           
            this.insertIntoChart(title, carbonFootprintObject.carbonFootprint);
            this.setState({ carbonFootprintList: array, footprintCalculated:  Number(footprintCalculated.toFixed(6))});
            this.clearCarbonFootPrintObject();
        }
        
    }

    getMeasuresValue(){
        var measures = this.state.carbonFootprintObject.measures;
        var value = 1;
        if(measures.length > 0){
            for (let index = 0; index < measures.length; index++) {
                const measure = measures[index];
                value = value * measure.value;
                
            }
        }
        return value;
    }


    insertIntoChart(title, value){
        var chartArray = [...this.state.chartArray];
        
        var randomColor = "#000000".replace(/0/g, function () {
            return (~~(Math.random() * 16)).toString(16);
          });
          var added = false;
        for (let index = 0; index < chartArray.length; index++) {
            const element = chartArray[index];
            if(element.title.toString() === title.toString()){
                var newValue = Number(element.value.toFixed(6)) + Number(value.toFixed(6));
                element.value = Number(newValue.toFixed(6));
                this.setState({ chartArray: chartArray});
                added = true;
            }
            
        }
        
        if(!added){
            let chartObject = {
                color: randomColor,
                title: title,
                value: value,
              };
          
              chartArray.push(chartObject);
              this.setState({ chartArray: chartArray});
        }
          

    }

    deleteChartElement(title, value){
        var chartArray = [...this.state.chartArray];
        for (let index = 0; index < chartArray.length; index++) {
            const element = chartArray[index];
            if(element.title.toString() === title.toString()){
                var newValue = Number(element.value.toFixed(6)) - Number(value.toFixed(6));
                element.value = Number(newValue.toFixed(6));
                if(element.value.toString() === "0"){
                    chartArray.splice(index, 1);
                }
            }
        }
        this.setState({ chartArray: chartArray});
    }

    checkCarbonFootprintData(){
        var carbonFootprintObject = {...this.state.carbonFootprintObject};
        if(carbonFootprintObject.description === "" ||
            carbonFootprintObject.categoryName === "" ||
            carbonFootprintObject.subCategoryName === "" ||
            carbonFootprintObject.typeName === "" ||
            carbonFootprintObject.elementName === "" ){
                return false;

        }
        return true;
    }

    deleteCarbonFootprintRow (id)  {
        console.log(id);
        var array = [...this.state.carbonFootprintList];
        var footprintCalculated = this.state.footprintCalculated;
        console.log(array);
        for (let i = 0; i < this.state.carbonFootprintList.length; i++) {
            if(this.state.carbonFootprintList[i].id.toString().trim() === id.toString().trim()){
                footprintCalculated =  footprintCalculated - Number(this.state.carbonFootprintList[i].carbonFootprint.toFixed(6));
                array.splice(i, 1);
                var title = this.state.carbonFootprintList[i].categoryName + "-" + this.state.carbonFootprintList[i].subCategoryName;
                var value = this.state.carbonFootprintList[i].carbonFootprint;
                this.deleteChartElement(title, value);
                this.setState({ carbonFootprintList: array, footprintCalculated: Number(footprintCalculated.toFixed(6))});
                return;
            }
        }
    }

    onClickChart(index){
        var weight = this.state.chartArray[index].value;
        
        this.setState({selectedChartWeight: weight});
    }
    
    handleDescription = (event) => {
        var description = event.target.value;
        var carbonFootprintObject = {...this.state.carbonFootprintObject};
        carbonFootprintObject.description = description;
        this.setState({carbonFootprintObject: carbonFootprintObject});
    }

    handleMeasure = (event) => {
        console.log(event.target.value);
        console.log(event.target.getAttribute('data-id'));
        console.log(event.target.getAttribute('data-name'));
        if(this.validate(event.target.value)){

            var  carbonFootprintObject = {...this.state.carbonFootprintObject};
        var array = carbonFootprintObject.measures;
        var added = false;
        if(array.length > 0){
            for (let i = 0; i < array.length; i++) {
                let measure = array[i];
                if(measure.id === event.target.getAttribute('data-id')){
                    measure.value = event.target.value;
                    added = true;
                    carbonFootprintObject.measures = array;
                    this.setState({carbonFootprintObject:carbonFootprintObject});
                }
                
            }
        }
        if(!added){
            let measureObject = {
                id: event.target.getAttribute('data-id'),
                name: event.target.getAttribute('data-name'),
                value: event.target.value,
              };
          
              array.push(measureObject);
              carbonFootprintObject.measures = array;
              this.setState({ carbonFootprintObject: carbonFootprintObject});
        }

        }else{
            event.target.value = "";
        }
        
        
    }

    /*handleQuantity = (event) => {
        var quantity = event.target.value.replace(/\D/,'');
        var carbonFootprintObject = {...this.state.carbonFootprintObject};
        carbonFootprintObject.quantity = quantity;
        this.setState({carbonFootprintObject: carbonFootprintObject});
    }*/

    goToNewProject (){
        this.props.history.push("/project");
    }

     validate(s) {
        var rgx = /^[0-9]*\.?[0-9]*$/;
        return s.match(rgx);
    }

    clearCarbonFootPrintObject(){
        var carbonFootprintObject = {...this.state.carbonFootprintObject};
        carbonFootprintObject.id = 0;
        carbonFootprintObject.categoryName = '';
        carbonFootprintObject.subCategoryName = '';
        carbonFootprintObject.typeName = '';
        carbonFootprintObject.elementName = '';
        carbonFootprintObject.countryName = '';
        carbonFootprintObject.description = '';
        carbonFootprintObject.weight = 0;
        carbonFootprintObject.carbonFootprint = 0;
        carbonFootprintObject.quantity = 0;
        carbonFootprintObject.measures = [];
        this.setState({ carbonFootprintObject: carbonFootprintObject, 
            categories: [],
            subCategories: [],
            types: [],
            elements: [],
            countries: [],
            measures: []});
        this.getCategories();        
        

    }

render() {
    return (
        <>
        <div >
            <Navbar />
            <br></br>
            <div>

            <h2 className="project-title">{this.state.projectName}</h2>
            <p className="project-title">Start Date: {this.state.startDate} - End Date: {this.state.endDate} </p>
            </div>
            <div style={{display:"flex", justifyContent: "space-evenly"}}>
            <div className="global-container-footprint">
                {/*<div className="title-desc-input">
                    <p style={{marginRight: 25}}>Insert description</p>
                    
                </div>*/}
                
                
                <div className="">
                        <div className=" row ">
                                <div className="col-md-6 position-input">
                                    <p className=" title-style" >Insert description</p>
                                    <input type="text" name="" value={this.state.carbonFootprintObject.description} className="project-name" id="input-description"  onChange={this.handleDescription} />
                                    
                                </div>
                        </div>
                        <hr className="hr-style" />
                    <div className="row">
                        {/* Category  */}
                        <div className=" col-md-6 position-input">
                            <p className="title-style">Category</p>
                            <select className="project-name" aria-label="Categories select"  onChange={this.handleCategoryChange} required>
                                
                                {this.state.categories.map((category) => (
                                    <option value={category.id}>{category.title}</option>
                                ))}
                            </select>
                        </div>
                        {/* Sub Category  */}
                        <div className=" col-md-6 position-input">
                            <p className="title-style">Subcategory</p>
                            <select className="project-name" aria-label="sub category select example" onChange={this.handleSubCategoryChange} required>
                            
                                {this.state.subCategories.map((subCategory) => (
                                    <option value={subCategory.id}>{subCategory.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6 position-input">
                            <p className="title-style">Type</p>
                            <select className="project-name" aria-label="type select example" onChange={this.handleTypeChange} required>
                            
                                {this.state.types.map((type) => (
                                    <option value={type.id}>{type.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-6 position-input">
                            <p className="title-style">Element</p>
                            <select defaultValue={this.state.selectedElement} className="project-name" aria-label="element select example" onChange={this.handleElementChange}  >
                            
                                {this.state.elements.map((element,index) => (
                                    <option selected={element.id === this.state.selectedElement.id}   key={index} value={element.id}>{element.title}</option>
                                ))}
                            </select>
                        </div>
                        
                    
                    </div>
                    <div className="row">
                        <div className="col-md-6 position-input">
                            <p className="title-style">Country</p>
                            <select className="project-name" aria-label="type select example" onChange={this.handleTypeChange} required>
                            
                                {this.state.countries.map((country) => (
                                    <option value={country.id}>{country.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <hr className="hr-style" />
                    {this.state.measures.map((measure) => (
                    <div className=" row">
                        <div className=" col-md-6 position-input">
                        <p className=" title-style" >{measure.name}</p>
                        <input type="text" name="" className="project-name" id="input-volume" data-name={measure.name} data-id={measure.id} onChange={this.handleMeasure} />
                        </div>
                    </div>
                       
                ))}
                
               
                </div>
                
                         
                <button className="button-calculate" type="button" onClick={this.addButtonClicked}>Add</button>         
            </div>
            <div className="col-md-4">
            <img src="/images/image_emission_co2_v2.png" alt="" className="image-infos" />
            </div>
            </div>
            
            
            <br></br>
            <br></br>
            <br></br>
            <div className="table-style table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Description </th>
                                <th>Category Name </th>
                                <th>Sub Category Name </th>
                                <th>Type Name </th>
                                <th>Element Name </th>
                                <th>Country </th>
                                <th>Measures </th>
                                <th>Kg eq.CO<sub>2</sub></th>
                                <th>Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.carbonFootprintList.map(
                                    carbonFootprint =>
                                        <tr key={carbonFootprint.id}>
                                            <td> {carbonFootprint.description} </td>
                                            <td> {carbonFootprint.categoryName} </td>
                                            <td> {carbonFootprint.subCategoryName} </td>
                                            <td> {carbonFootprint.typeName} </td>
                                            <td> {carbonFootprint.elementName} </td>
                                            <td> {carbonFootprint.countryName} </td>
                                            <td>{carbonFootprint.measures.map((measure) => (
                                                <div>{measure.name}:{measure.value}</div>
                                                    ))}</td>
                                            <td> {carbonFootprint.carbonFootprint} </td>
                                            <td> <button onClick={() => this.deleteCarbonFootprintRow(carbonFootprint.id)}  className="btn btn-danger trash-action "><FontAwesomeIcon className="trash-icon " icon={faTrash}/>  </button> </td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <p style={{backgroundColor: "#1c7f8a",
                            color: "white",
                            padding: "0.5em",
                            textAlign: "center",
                            fontSize: "larger",
                            marginTop:"6em"
                            }}>Corresponding consumption</p>

                </div>
                <br></br>
                <br></br>
                <br></br>
                
                <div className="row">
                {this.state.chartArray.length > 0 ?

                    <div className=" col-md-4 d-flex justify-content-around align-items-center">
                    
                    <div id="cercle"><div className="text-cercle">{this.state.footprintCalculated} Kg eq.CO<sub>2</sub></div></div>

                    </div>: ''} 
                <div className=" col-md-9 chart-container">
                    <PieChart
                        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                        animate={true}
                        animationDuration={500}
                        animationEasing="ease-out"
                        data={this.state.chartArray}
                        lineWidth={100}
                        startAngle={0}
                        viewBoxSize={[100, 100]}
                        label={({dataEntry}) => dataEntry.title }
                        labelPosition={65}
                        labelStyle={{
                            fill:"#FFFFFF",
                            fontSize: "3.5px",
                        }}
                        onClick={(event, index) => {
                            this.onClickChart(index);
                            console.log('CLICK', { event, index });
                        }}
                    />
                   {this.state.chartArray.length > 0 ? <input id="weight-input" type="text" disabled className="project-name align-items-center" placeholder="Weight" value={this.state.selectedChartWeight}  />: <br></br>} 
                   <br></br>
                   <br></br>
                </div>
                <div className="bottom-button-carbonfootprint">
                    <button className="btn btn-bottom-carbonfootprint">Save</button>
                    <button className="btn btn-bottom-carbonfootprint" onClick={this.goToNewProject}>New Project</button>
                </div>

            </div> 
            <br></br>
            <br></br>
            <Footer />  
        </div >  
            </>    
    );
}
}

export default CarbonFootprintComponent;
