import axios from 'axios';
import globalConstants from '../utils/globalConstants';
const CATEGORY_API_BASE_URL = "/categories";
const SUB_CATEGORIES_BY_CATEGORY_ID = "/subcategories/category/";
const TYPES_BY_SUB_CATEGORY_ID = "/types/subcategory/";
const ELEMENTS_BY_TYPE_ID = "/elements/type/";

class CarbonFootprintService {
    
    getToken=()=>{
        return localStorage.getItem('USER_KEY');
    }

    getCategories() {
        return  axios({
            method:'GET',
            url: globalConstants.BASE_URL + CATEGORY_API_BASE_URL,
            headers:{
                'Authorization':'Bearer ' + this.getToken()
            }
        });
    }

    getSubCategoriesByCategoryId(id){
        return  axios({
            method:'GET',
            url: globalConstants.BASE_URL + SUB_CATEGORIES_BY_CATEGORY_ID + id,
            headers:{
                'Authorization':'Bearer ' + this.getToken()
            }
        });
    }

    getTypesBySubCategoryId(id){
        return  axios({
            method:'GET',
            url: globalConstants.BASE_URL + TYPES_BY_SUB_CATEGORY_ID + id,
            headers:{
                'Authorization':'Bearer ' + this.getToken()
            }
        });
    }

    getElementsByTypeId(id){
        return  axios({
            method:'GET',
            url: globalConstants.BASE_URL + ELEMENTS_BY_TYPE_ID + id,
            headers:{
                'Authorization':'Bearer ' + this.getToken()
            }
        });
    }
}

export default new CarbonFootprintService()