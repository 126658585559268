import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { UserContext } from "./UserContext";
import { Footer } from "./footer/Footer";
import "./footer/Footer.scss";
import "./Login.scss";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Login = () => {
  const history = useHistory();

  const user = useContext(UserContext);

  function handleNav(values) {
    user.setEmail(values.email);
   //history.push("/carbon-footprint");
  }

  const [state, setState] = useState({
    email : "",
    password :"",
    show : true

  })

  const handleChangeDetail = evt => {
    setState(
      {
        ...state,
        [evt.target.name]: evt.target.value
      }
    )
  }
  const verifyCredential = values => {
    //console.log(values.email.match("@amaris.com"));
    //console.log(state.email);

    // let email = .email
    
    //eslint-disable-next-line eqeqeq
    if ( (state.email.match("@amaris.com") || state.email.match("@resp3ct.io") || state.email.match("@mantu.com")) && (state.password=="Pass1234")){
    history.push("/project");
    } else {
      setState({...state,show:false})
      history.push("/");
    }
  }

  return (
    <div className="body-page">
      <div
        className=" p-1
        d-flex flex-column justify-content-center align-items-center"
      >
        <Formik
          onSubmit={handleNav}
          initialValues={{ email: "", password: "" }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="form-style p-5 d-flex flex-column rounded"
              onSubmit={handleSubmit}
            >
              <img
                src="../../images/logo-poc-blanc.png"
                className="logo-login-style"
                alt="logo-respect"
              />
              <div className="input-container">
                <FontAwesomeIcon className="icon" icon={faUser} />
                <input
                  type="emails"
                  name="email"
                  onChange={handleChangeDetail}
                  onBlur={handleBlur}
                  value={state.email}
                  placeholder="USERNAME"
                  className="input-field"
                />
              </div>

              <div className="input-container">
                <FontAwesomeIcon className="icon" icon={faLock} />
                <input
                  type="password"
                  name="password"
                  onChange={handleChangeDetail}
                  onBlur={handleBlur}
                  value={state.password}
                  placeholder="......"
                  className="input-field"
                />
              </div>

              <div className="checkbox-login-container">
                <input
                  style={{ marginRight: 5 }}
                  className="checkbox-login"
                  type="checkbox"
                  name="checkout"
                  id="out"
                />
                <div>
                  <p className="checkbox-login-text">Remember me </p>

                  <a href="/#" className="link-forget-text">Forgot your password? </a>
                </div>
              </div>
              <hr className="hr-login-style" />

              <div className="text-white" hidden={state.show}>
                <h6 style={{ fontWeight : "bold"}}>
                Invalid Email or Password, Please retry.
                </h6>
              </div>

              <button
                type="submit"
                className="login-button btn btn-primary mt-2"
                //onClick={() => history.push("/project")}
                // eslint-disable-next-line no-undef
                onClick={verifyCredential}
              >
                LOGIN
              </button>
              <a href="/#">
                <img
                  src="../../images/am.jpg"
                  className="logo-form-am"
                  alt="logo-am"
                />
              </a>
            </form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
};
