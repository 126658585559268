export const Navbar = () => {
  return (
    <header className="header">
      <nav>
        <div className="logo-position">
          <a href="/#">
            <img
              src="../../images/logo-poc.png"
              className="logo-style"
              alt="logo-respect"
            />
          </a>
        </div>
      </nav>
    </header>
  );
};
