export const Footer = () => {
  return (
    <div className="footer">
      <div className="respect-style-footer">
        <p className="footer-text">
          <a href="/#">
            <img
              src="../../images/logo-poc.png"
              className="logo-footer"
              alt="logo-respect"
            />
          </a>
          - All rights reserved © 2021 POC
        </p>
      </div>
      <div className="respect-style-footer">
        <p className="footer-text">
          <a href="/#">
            <img
              src="../../images/am.jpg"
              className="second-logo-footer"
              alt="logo-respect"
            />
          </a>
          Powered by Amaris{" "}
        </p>
      </div>
    </div>
  );
};
