import React from "react";

import { Login } from "./Components/Login";
import CarbonFootprintComponent from "./Components/CarbonFootprintComponent";
import { Results } from "./Components/Results";
import ProjectComponent from "./Components/ProjectComponent";
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Route exact path="/"  component={Login} />
        <Route exact path="/project" component={ProjectComponent} />
        <Route
          exact
          path="/carbon-footprint"
          component={CarbonFootprintComponent}
        />
        <Route exact path="/results" component={Results} />
      </Router>
    </div>
  );
}

export default App;
