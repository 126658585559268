import React, { Component } from 'react';
import "./Project.scss";
import { Navbar } from "./navbar/Navbar";
import { Footer } from "./footer/Footer";

class ProjectComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            projectName: '',
            startDate: '',
            endDate: ''
        }
        this.handleProjectNameChange = this.handleProjectNameChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    componentDidMount() {
        
    }

    handleProjectNameChange = (event) => {
        this.setState({projectName: event.target.value});
    }

    handleStartDateChange = (event) => {
        this.setState({startDate: event.target.value});
    }

    handleEndDateChange = (event) => {
        this.setState({endDate: event.target.value});
    }

render() {
    return (
        <>
          <Navbar />
          <div
            className="container-fluid bg-light d-flex flex-column align-items-center"
          >
            <h2
              style={{
                fontWeight: 700,
                marginTop: 60,
                marginBottom: 60,
                color: "#1c7f8a",
              }}
            >
              Create a new Project
            </h2>
    
            <div style={{ display: "contents" }}>
              <form action="" style={{ display: "grid" }}>
                <div className="container-input-project-style">
                  <p className="p-project-style">Project Name</p>
                  <input
                    className="input-project-style"
                    type="text"
                    
                    onChange={this.handleProjectNameChange}
                  />
                </div>
                <div className="container-input-project-style">
                  <p className="p-project-style">Start date</p>
                  <input
                    className="input-project-style"
                    type="text"
                    name=""
                    id=""
                    onFocus={(e) => (e.currentTarget.type = "date")}
                    onBlur={(e) => (e.currentTarget.type = "text")}
                    onChange={this.handleStartDateChange}
                  />
                </div>
                <div className="container-input-project-style">
                  <p className="p-project-style">End date</p>
                  <input
                    className="input-project-style"
                    type="text"
                    name=""
                    id=""
                    
                    onFocus={(e) => (e.currentTarget.type = "date")}
                    onBlur={(e) => e.target.placeholder = 'text'}
                    onChange={this.handleEndDateChange}
                  />
                </div>
              </form>
              <button
                className="button-project-style"
                // type="button"
                // value="Start a new project"
                // style={{
                //   background: "none",
                //   border: "none",
                //   fontWeight: "bold",
                //   fontSize: "large",
                // }}
                onClick={() => this.props.history.push("/carbon-footprint", {projectName: this.state.projectName, startDate: this.state.startDate, endDate: this.state.endDate})}
              >
                Create
              </button>
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </>
      );
    }
}

export default ProjectComponent;