import React, { useState } from "react";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [color, setColor] = useState("");

  return (
    <UserContext.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        color,
        setColor,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
