import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import { useContext } from "react";
import { Navbar } from "./navbar/Navbar";
//import emprunte from "../../public/empreinte-carbone.jpeg";
import "./Results.scss";
import { Footer } from "./footer/Footer";
import "./footer/Footer.scss";

export const Results = () => {
  const user = useContext(UserContext);
  return (
    <>
      <Navbar />
      <div
        className="container-fluid p-5 bg-light
        d-flex flex-column justify-content-center align-items-center vh-100"
      >
        <p>Heyyy {user.colors},</p>
        <div className="d-flex justify-content-around align-items-center">
          {/* <img src="/images/empreinte-carbone.jpeg" alt="" className="m-5" /> */}
          <div id="cercle">
            <div className="text-cercle">test empreinte</div>
          </div>
          <p>
            Estimation de l'empreinte carbone: <br />
            <strong>365 gr de CO2</strong>
          </p>
        </div>
        <Link to="/">Home</Link>
        <br />
        <Link to="/form">Form</Link>
        <Footer />
      </div>
    </>
  );
};
